<script setup lang="ts">
/**
 * How to use:
 * In your script portion of you page or component, set it up like this:
 * ```
 * const { showToast } = useToast()
 * ```
 * Then in the script, use something like this to show a toast:
 * ```
 * showToast('material-symbols:dashboard', 'This is a custom message!')
 * ```
 * You can pass 2 parameters to the showToast function, the first one is the icon name, and the second one is the message.
 *
 */

import type { ToastData } from "~/types/ui";

const toast = useState<ToastData | null>("toast", () => null);

const { closeToast } = useToast();
</script>

<template>
  <div
    v-if="toast !== null"
    id="toast-default"
    class="toast fixed bottom-10 right-10 transition-transform z-[100]"
    role="alert"
  >
    <div
      class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
      :class="{
        'text-blue-500 bg-blue-100 dark:bg-blue-800 dark:text-blue-200':
          toast.variant === 'info',
        'text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200':
          toast.variant === 'error',
        'text-yellow-500 bg-yellow-100 dark:bg-yellow-800 dark:text-yellow-200':
          toast.variant === 'warning',
        'text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200':
          toast.variant === 'success',
      }"
    >
      <Icon :name="toast.iconName" class="w-5 h-5" />
    </div>
    <div class="ms-3 text-sm font-normal">
      {{ toast.message }}
    </div>
    <button
      type="button"
      class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
      aria-label="Close"
      @click="closeToast"
    >
      <span class="sr-only">Close</span>
      <svg
        class="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</template>
